import React, { useEffect, useState } from "react";
import { useSnapshot } from "valtio";
import { widState } from "../Store/workdriveState";

const ZOHO = window.ZOHO;

function useCrmVariable({ Entity = "", EntityId = "", crmVariableName = "" }) {
  const widSnap = useSnapshot(widState);
  const [crmVariable, setCrmVariable] = useState(null);
  // FIXME Comment out the below reurn code and send data from zoho crm variable
  return {
    orgid: widState.orgId,
    connname: "easysharepointforcrm",
    apikey: widState.apiKey,
    zuid: widState.zuid,
    datacenterurl: widState.dataCenterUrl,
    zapiKey: widState?.zapiKey
  };
  return crmVariable;
}

export default useCrmVariable;
