import axios from "axios";
const base_url = process.env.REACT_APP_API_SERVER_URL;
const setting_url = `${process.env.REACT_APP_ADMIN_SERVER_URL}/db/planetscale/clients/settings`;

const headers = {
  Accept: "*/*",
  "Content-Type": "application/json",
};

// TODO connname, orgid, apikey should get from CRM variable
export const getCronJobs = async (data) => {
  const { connname, orgid, apikey } = data;

  try {
    const base_url = process.env.REACT_APP_ADMIN_SERVER_URL;
    const crons = await axios({
      url: base_url + "/utils/cronjobs",
      method: "get",
      headers: {
        ...headers,
        orgid: orgid,
        connname: connname,
        apikey: apikey,
      },
    });
    return crons?.data;
  } catch (error) {
    return error;
  }
};

// TODO connname, orgid, apikey should get from CRM variable
export const createCronJob = async (data) => {
  const { reqUrl, reqParams, reqHeaders, reqBody, reqType } = data;
  const { orgid, connname, apikey, datacenterurl } = reqHeaders;
  let params = {};

  try {
    const base_url = process.env.REACT_APP_ADMIN_SERVER_URL;
    const createCron = await axios({
      url: base_url + "/utils/cronjobs",
      method: "post",
      headers: {
        ...headers,
        orgid: orgid,
        connname: connname,
        apikey: apikey,
        datacenterurl: datacenterurl,
      },
      data: {
        reqUrl: reqUrl,
        reqParams: reqParams,
        reqHeaders: reqHeaders,
        reqBody: reqBody,
        reqType: reqType,
      },
    });
    return createCron?.data;
  } catch (error) {
    return error;
  }
};

// TODO connname, orgid, apikey should get from CRM variable
export const getCustomViewData = async (data) => {
  const {
    moduleApiName,
    cvid,
    connname,
    orgid,
    apikey,
    datacenterurl,
    settingUrl = process.env.REACT_APP_DRIVEMANAGER_SETTING_URL,
  } = data;

  // You can pass the data in body/parsms
  let reqOptions = {
    url: `${process.env.REACT_APP_ADMIN_SERVER_URL}/data/zoho/crm/modules/${moduleApiName}/cvid/${cvid}`,
    method: "GET",
    headers: {
      ...headers,
      connname: "easysharepointforcrm__zoho",
      orgid,
      apikey,
      Datacenterurl: datacenterurl,
    },
  };

  try {
    const settingResp = await axios(reqOptions);
    return settingResp?.data;
  } catch (error) {
    return { error: error?.response?.statusText };
  }
};

// TODO connname, orgid, apikey should get from CRM variable
export const getSettingData = async (
  moduleApiName = "",
  settingType = "",
  connname,
  orgid,
  apikey,
  datacenterurl,
  settingUrl = process.env.REACT_APP_DRIVEMANAGER_SETTING_URL
) => {
  let params = {};

  if (moduleApiName != "") {
    params["moduleApiName"] = moduleApiName;
  }

  if (settingType != "") {
    params["settingType"] = settingType;
  }

  let reqOptions = {
    url: `${setting_url}`,
    method: "GET",
    headers: {
      ...headers,
      connname: "easysharepointforcrm",
      orgid,
      apikey,
      datacenterurl,
    },
    params: params,
  };

  try {
    const settingResp = await axios(reqOptions);

    return settingResp?.data;
  } catch (error) {
    return { error: error?.response?.statusText };
  }
};
// TODO connname, orgid, apikey should get from CRM variable

export const upsertData = async (
  data = {},
  connname,
  orgid,
  apikey,
  datacenterurl,
  settingUrl = process.env.REACT_APP_DRIVEMANAGER_SETTING_URL
) => {
  let reqOptions = {
    url: `${setting_url}`,
    method: "POST",
    headers: {
      ...headers,
      connname: "easysharepointforcrm",
      orgid,
      apikey,
      datacenterurl,
    },
    data: data,
  };

  try {
    const folderResp = await axios(reqOptions);
    return folderResp?.data;
  } catch (error) {
    return { error: error?.response?.statusText };
  }
};
// upsertData
// TODO connname, orgid, apikey should get from CRM variable
export const getSharePointSites = async ({
  connname,
  orgid,
  apikey,
  datacenterurl,
}) => {
  let reqOptions = {
    url: `${base_url}/root/sites`,
    method: "GET",
    headers: {
      ...headers,
      connname: "easysharepointforcrm",
      orgid,
      apikey,
      datacenterurl,
    },
  };

  try {
    const sitesResp = await axios(reqOptions);
    return sitesResp?.data;
  } catch (error) {
    return { error: error?.response?.statusText };
  }
};

// TODO connname, orgid, apikey should get from CRM variable
export const getSiteDrives = async ({
  connname,
  orgid,
  apikey,
  siteId,
  datacenterurl,
}) => {
  let reqOptions = {
    url: `${base_url}/sites/drives`,
    method: "post",
    headers: {
      ...headers,
      connname: "easysharepointforcrm",
      orgid,
      apikey,
      datacenterurl,
    },
    data: {
      siteId: siteId,
    },
  };

  try {
    const driveResp = await axios(reqOptions);
    return driveResp?.data;
  } catch (error) {
    return { error: error?.response?.statusText };
  }
};

// TODO connname, orgid, apikey should get from CRM variable
export const getDriveRootFolders = async ({
  connname,
  orgid,
  apikey,
  siteId,
  driveId,
  datacenterurl,
}) => {
  let reqOptions = {
    url: `${base_url}/drive/files`,
    method: "post",
    headers: {
      ...headers,
      connname: "easysharepointforcrm",
      orgid,
      apikey,
      datacenterurl,
    },
    data: {
      siteId: siteId,
      driveId: driveId,
    },
  };

  try {
    const driveResp = await axios(reqOptions);
    return driveResp?.data;
  } catch (error) {
    return { error: error?.response?.statusText };
  }
};

export const getFoldersData = async (
  folder_id = "root",
  connname,
  orgid,
  apikey,
  datacenterurl,
  settingUrl = process.env.REACT_APP_DRIVEMANAGER_SETTING_URL,
  driveId
) => {
  let reqOptions = {
    url: `${base_url}/file/details`,
    method: "post",
    headers: {
      ...headers,
      connname: "easysharepointforcrm",
      orgid,
      apikey,
      datacenterurl,
    },
    data: {
      fileId: folder_id,
      driveId: driveId,
    },
  };

  try {
    const folderResp = await axios(reqOptions);
    return folderResp?.data;
  } catch (error) {
    return { error: error?.response?.statusText };
  }
};

// Get Folders Item
export const getFoldersItem = async (
  folder_id,
  connname,
  orgid,
  apikey,
  datacenterurl,
  settingUrl = process.env.REACT_APP_DRIVEMANAGER_SETTING_URL,
  driveId,
  siteId = "",
  onlyFolder = "false"
) => {
  let url = `${base_url}/files`;
  if (onlyFolder) {
    url = url + `?onlyFolder=${onlyFolder}`;
  }
  let reqOptions = {
    url: url,
    method: "post",
    headers: {
      ...headers,
      connname: "easysharepointforcrm",
      orgid,
      apikey,
      datacenterurl,
    },
    data: {
      fileId: folder_id,
      driveId: driveId,
      siteId: siteId,
    },
  };

  try {
    const folderResp = await axios(reqOptions);
    return folderResp?.data;
  } catch (error) {
    return { error: error?.response?.statusText };
  }
};

// Get Folders Item
export const getDriveRootFoldersItem = async (
  connname,
  orgid,
  apikey,
  datacenterurl,
  settingUrl = process.env.REACT_APP_DRIVEMANAGER_SETTING_URL
) => {
  let reqOptions = {
    url: `${base_url}/root/files`,
    method: "GET",
    headers: {
      ...headers,
      connname: "easysharepointforcrm",
      orgid,
      apikey,
      datacenterurl,
    },
  };

  try {
    const folderResp = await axios(reqOptions);
    return folderResp?.data;
  } catch (error) {
    return { error: error?.response?.statusText };
  }
};

export const shareFileFolder = (
  data,
  file,
  connname,
  orgid,
  apikey,
  datacenterurl,
  settingUrl = process.env.REACT_APP_DRIVEMANAGER_SETTING_URL,
  driveId
) => {
  const URL = `${base_url}/share`;

  let headersList = {
    Accept: "*/*",
    "Content-Type": "application/json",
    orgid: orgid,
    connname: "easysharepointforcrm",
    apikey: apikey,
    datacenterurl,
  };

  let reqOptions = {
    url: URL,
    method: "POST",
    headers: headersList,
    data: {
      ...data,
      driveId: driveId,
      fileId: file.id,
    },
  };

  return axios(reqOptions)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const shareFullAccess = (
  data,
  file,
  connname,
  orgid,
  apikey,
  datacenterurl,
  driveId
) => {
  const URL = `${base_url}/share/fullaccess`;

  let headersList = {
    Accept: "*/*",
    "Content-Type": "application/json",
    orgid: orgid,
    connname: "easysharepointforcrm",
    apikey: apikey,
    datacenterurl,
  };

  let reqOptions = {
    url: URL,
    method: "POST",
    headers: headersList,
    data: {
      ...data,
      driveId: driveId,
      fileId: file.id,
    },
  };

  return axios(reqOptions)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const createFolder = (
  folder_id,
  data,
  connname,
  orgid,
  apikey,
  datacenterurl,
  settingUrl = process.env.REACT_APP_DRIVEMANAGER_SETTING_URL,
  driveId,
  siteId
) => {
  const URL = `${base_url}/create`;

  let reqOptions = {
    url: URL,
    method: "POST",
    headers: {
      ...headers,
      connname: "easysharepointforcrm",
      orgid,
      apikey,
      datacenterurl,
    },
    data: {
      ...data,
      fileId: folder_id,
      driveId: driveId,
      siteId: siteId
    },
  };

  return axios(reqOptions)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const fileUploader = (
  data,
  parentId,
  connname,
  orgid,
  apikey,
  datacenterurl,
  settingUrl = process.env.REACT_APP_DRIVEMANAGER_SETTING_URL,
  driveId
) => {
  const URL = `${base_url}/create?fileId=${parentId}&driveId=${driveId}`;

  return axios
    .post(URL, data, {
      headers: {
        connname: "easysharepointforcrm",
        apikey: apikey,
        datacenterurl,
        orgid: orgid,
        datacenterurl,
        Accept: "application/vnd.api+json",
      },
    })
    .then((response) => response?.data)
    .catch((error) => {
      throw error;
    });
};

export const renameFileFolder = (
  data,
  file,
  connname,
  orgid,
  apikey,
  datacenterurl,
  settingUrl = process.env.REACT_APP_DRIVEMANAGER_SETTING_URL,
  driveId
) => {
  // const URL = `${base_url}/v1/files/${file.id}`;

  const URL = `${base_url}/rename`;
  let headersList = {
    Accept: "*/*",
    "Content-Type": "application/json",
    orgid: orgid,
    connname: "easysharepointforcrm",
    apikey: apikey,
    datacenterurl,
  };
  let reqOptions = {
    url: URL,
    method: "post",
    headers: headersList,
    data: {
      name: file?.extn ? `${data.name}.${file?.extn}` : data.name,
      fileId: file.id,
      driveId: driveId,
    },
  };
  return axios(reqOptions)
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      throw error;
    });
};

// Working
export const deleteFileFolder = (
  file,
  connname,
  orgid,
  apikey,
  datacenterurl,
  settingUrl = process.env.REACT_APP_DRIVEMANAGER_SETTING_URL,
  driveId
) => {
  const URL = `${base_url}/delete`;
  let headersList = {
    Accept: "*/*",
    "Content-Type": "application/json",
    orgid: orgid,
    connname: "easysharepointforcrm",
    apikey: apikey,
    datacenterurl,
  };
  let reqOptions = {
    url: URL,
    method: "post",
    headers: headersList,
    data: {
      fileId: file.id,
      driveId: driveId,
    },
  };
  return axios(reqOptions)
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      throw error;
    });
};

// Working
export const moveFile = (
  file,
  child,
  connname,
  orgid,
  apikey,
  datacenterurl,
  settingUrl = process.env.REACT_APP_DRIVEMANAGER_SETTING_URL,
  driveId,
  name
) => {
  const URL = `${base_url}/move`;

  return axios
    .post(
      URL,
      {
        fileId: child?.id,
        driveId: driveId,
        destFolderId: file?.id,
        name: name,
      },
      {
        headers: {
          connname: "easysharepointforcrm",
          apikey: apikey,
          datacenterurl,
          orgid: orgid,
          datacenterurl,
          Accept: "application/vnd.api+json",
        },
      }
    )
    .then((response) => {
      // ;
      return response?.data;
    })
    .catch((error) => {
      // // ;
      throw error;
    });
};

// TODO: check this
export const getBreadCrumbs = (
  fileId,
  connname,
  orgid,
  apikey,
  datacenterurl,
  settingUrl = process.env.REACT_APP_DRIVEMANAGER_SETTING_URL
) => {
  const URL = `${base_url}/${fileId}/breadCrumbs`;

  return axios({
    method: "get",
    url: URL,
    headers: {
      connname: connname,
      apikey: apikey,
      datacenterurl,
      orgid: orgid,
      Accept: "application/vnd.api+json",
    },
  })
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      throw error;
    });
};

// TODO: check this
export const searchInsideFolder = (
  fileId,
  query,
  connname,
  orgid,
  apikey,
  datacenterurl,
  settingUrl = process.env.REACT_APP_DRIVEMANAGER_SETTING_URL
) => {
  const URL = `${base_url}/${fileId}/search/insideFolder`;

  return axios({
    method: "post",
    url: URL,
    headers: {
      connname: connname,
      apikey: apikey,
      datacenterurl,
      orgid: orgid,
      Accept: "application/vnd.api+json",
    },
    data: {
      query: query,
    },
  })
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      throw error;
    });
};

// Working
export const copyFile = (
  file,
  childId,
  connname,
  orgid,
  apikey,
  datacenterurl,
  settingUrl = process.env.REACT_APP_DRIVEMANAGER_SETTING_URL,
  driveId,
  name
) => {
  const URL = `${base_url}/copy`;
  return axios
    .post(
      URL,
      {
        destFolderId: file.id,
        fileId: childId,
        driveId: driveId,
        destDriveId: driveId,
        name: name,
      },
      {
        headers: {
          connname: "easysharepointforcrm",
          apikey: apikey,
          datacenterurl,
          orgid: orgid,
          datacenterurl,
          Accept: "application/vnd.api+json",
        },
      }
    )
    .then((response) => {
      // // ;
      return response?.data;
    })
    .catch((error) => {});
};

export const getImageResponse = (
  file,
  connname,
  orgid,
  apikey,
  datacenterurl,
  settingUrl = process.env.REACT_APP_DRIVEMANAGER_SETTING_URL,
  driveId
) => {
  const URL = `${base_url}/download`;
  return axios({
    method: "post",
    headers: {
      Accept: "application/vnd.api+json",
      connname: "easysharepointforcrm",
      apikey: apikey,
      orgid: orgid,
      datacenterurl,
    },
    responseType: "blob",
    url: URL,
    data: {
      fileId: file.id,
      driveId: driveId,
    },
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // // ;
      // throw error;
    });
};

export const getThumbnailData = (
  file,
  connname,
  orgid,
  apikey,
  datacenterurl,
  settingUrl = process.env.REACT_APP_DRIVEMANAGER_SETTING_URL,
  driveId
) => {
  const URL = `${base_url}/thumbnail`;

  return axios({
    method: "post",
    headers: {
      connname: "easysharepointforcrm",
      apikey: apikey,
      orgid: orgid,
      datacenterurl,
    },
    data: {
      thumbnail: file?.thumbnail,
      fileId: file.id,
      driveId: driveId,
    },
    responseType: "blob",
    url: URL,
  })
    .then((response) => {
      var blodData = new Blob([response.data]);
      return response;
    })
    .catch((error) => {
      // // ;
      throw error;
    });
};

export const syncAttachment = (
  folder_id,
  data,
  connname,
  orgid,
  apikey,
  datacenterurl,
  driveId
) => {
  const URL = `${base_url}/sync/acctachment`;

  let reqOptions = {
    url: URL,
    method: "POST",
    headers: {
      ...headers,
      connname: "easysharepointforcrm",
      orgid,
      apikey,
      datacenterurl,
    },
    data: {
      ...data,
      fileId: folder_id,
      driveId: driveId
    },
  };

  return axios(reqOptions)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      throw error;
    });
};
